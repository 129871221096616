.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  /* background-color: #282c34; */
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

p,
h1,
h2,
h3 {
  color: black;
}
/******************* AgGrid CSS *******************/

/* Supprimer les bordures des cellules */
.ag-theme-alpine .ag-cell {
  border: none !important;
}

/* Supprimer les bordures des en-têtes de colonnes */
.ag-theme-alpine .ag-header-cell {
  border: none !important;
}

/* Supprimer la bordure entre les lignes */
.ag-theme-alpine .ag-row {
  border-bottom: none !important;
}

/* Supprimer les bordures extérieures autour du tableau */
.ag-root-wrapper {
  border: none !important;
  box-shadow: none !important; /* Si une ombre est appliquée */
}

/* Supprimer les bordures des conteneurs des en-têtes */
.ag-theme-alpine .ag-header {
  border: none !important;
}

/* Supprimer la bordure des colonnes pinned à droite */
.ag-theme-alpine .ag-pinned-right-header,
.ag-theme-alpine .ag-pinned-right {
  border: none !important; /* Désactive la bordure */
}
